<template>
  <div>
    <v-dialog :retain-focus="false" max-width="720px" :value="mfaOTPDialogStatus
      " persistent>
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="body-1">
            Multi Factor Authentication (2FA)
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container style="padding: 24px 24px 16px 24px;">
          <v-row>
            <v-col cols="12">
              <v-text-field readonly disabled autocomplete="off" v-model.trim="username" label="Username" type="text"
                hide-details></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field autocomplete="off" v-model.trim="OTP" label="Enter OTP" type="text"
                hide-details></v-text-field>
            </v-col>
            <v-col cols="12" class="mx-auto">
              <v-alert :value="isAlert" :type="alertType">{{
                alertMessage
              }}</v-alert>
            </v-col>
            <v-col cols="12" justify="center" align="end">
              <v-btn text color="deep-purple accent-4" :loading="isLoading" @click="submitMFA">
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import getAuthConfig from "../getAuthConfig";
import kxBrowserUtils from "@common-ui/authenticator/api/browserUtils";

export default {
  props: {
    mfaOTPDialogStatus: Boolean,
    username: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
    kid: {
      type: String,
      default: "",
    },
    mfaMsg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uri: getAuthConfig().uri,
      alertMessage: "",
      isAlert: false,
      isLoading: false,
      alertType: "error",
      OTP: "",
      loginURI: getAuthConfig().sdkConf.KNX_UI,
    };
  },
  created() {
    this.alertType = "info"
    this.alertMessage = this.mfaMsg
    this.isAlert = true
  },
  watch: {
    mfaMsg(nv){
      this.alertMessage = nv
    }
  },
  methods: {
    closeDialog() {
      this.clearFields();
      this.resetAlert();
      this.$emit("closeMFAOtpDialog");
    },
    resetFormValidation() {
      if (this.OTP === "") {
        this.showAlert({
          message: "OTP cannot be empty.",
          type: "error",
        });
        return false;
      }
      if (this.OTP.length !== 6) {
        this.showAlert({
          message: "OTP must be of 6 characters.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    async submitMFA() {
      try {
        if (
          !this.resetFormValidation()
        ) {
          return;
        }
        this.resetAlert();
        this.isLoading = true;
        let url = this.uri.mfaURL;
        let response = await axios.post(url, {
          username: this.username,
          password: this.password,
          otp: this.OTP,
          kid: this.kid
        });
        if (!response.data.ok) {
          this.showAlert({
            message: response.data.message || "Failed to Login",
            type: "error",
          });
        } else {
          let authData = response.data;
          await this.$store.dispatch("authStore/setMortalFromKx", authData.token);
          this.$emit(
            "onLoginSuccess",
            authData.token,
            authData.refreshToken,
            authData.mortal
          );
        }
        this.isLoading = false;
      } catch (error) {
        console.log("error :>> ", error);
        this.isLoading = false;
        this.showAlert({
          message: "Error Occured while submitting OTP.",
          type: "error",
        });
      }
    },
    showAlert({ message, type }) {
      this.isAlert = true;
      this.alertType = type;
      this.alertMessage = message;
    },
    resetAlert() {
      this.isAlert = false;
    },
    clearFields() {
      this.OTP = ""
    },
    goToLoginPage() {
      this.clearFields();
      window.location = this.loginURI;
    },
  },
};
</script>
